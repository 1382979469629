.youtube-section {
  margin: 11rem auto 0rem auto;
  font-size: $small;

  a {
    font-size: 1.2rem;
    @media (min-width: 700px) {
      font-size: 2.5rem;
    }
  }

  form {
    margin-bottom: 1rem;

    display: flex;
    align-items: center;

    label {
      font-size: 1.2rem;
      @media (min-width: 700px) {
        font-size: 2.5rem;
      }
    }
    input {
      border-radius: 5px;
      min-height: 21px;
      margin-left: 0.5rem;
    }
    #change-video-btn {
      margin: 0 1rem;
      height: 100%;
    }
  }
}
.riffer-name {
  margin-top: 1rem;
}
#rifftube-player {
  max-width: 95vw;
}

.ytp-cued-thumbnail-overlay {
  max-width: 100%;
}

.rifftube-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.audio-capture {
  position: absolute;
  width: 100%;
  height: 390px;
  line-height: 390px;
  top: 0;
  text-align: center;
}

.rifftube-overlay {
  position: absolute;
  width: 100%;
  height: 390px;
  line-height: 390px;
  top: 0;
  text-align: center;
  pointer-events: none;
}

.rifftube-riffs-container {
  display: inline-block;
  vertical-align: middle;
  width: 640px;
  font: 36pt serif;
  background-color: rgba(255, 255, 255, 33%);
}

.title-and-url {
  text-align: center;
}

.App {
  margin: 0;
  padding: 0;
}

.main-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.control-panel {
  background-color: #202020;
  width: 100%;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $color-white;
  font-size: $small;
  max-height: 60rem;
  // border: 4px solid $color-primary;
  // border-left: 5px solid $color-primary;

  div {
    text-align: center;
  }
}

.edit-riff {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #202020cc;
  text-align: center;
  color: #202020;
  z-index: 1;
}

.edit-riff-inner {
  margin: 20% auto;
  padding: 1rem;
  background-color: rgb(145, 145, 145);
  width: 50%;
  font-size: 2rem;
  box-shadow: 0 0 1em red;
}

.edit-riff button {
  font-size: 1rem;
}

.list-of-riffs {
  width: 80%;
  overflow-y: auto;
  max-width: 50rem;
  min-height: 4.5em;
  position: relative;
}

.invisible {
  opacity: 0;
}

.riff-detail {
  border-radius: 5px;
  color: $color-black;
  background-color: $color-white;
  border: 5px solid $color-primary;
  width: 100%;
  min-height: 4.5em;
  box-sizing: border-box;
  position: absolute;
  transition: 0.5s all;

  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  font-size: $small;

  .audio-icon {
    flex: auto;
    padding: 1rem;
  }

  .riff-type-icon {
    align-items: center;

    img {
      height: 60%;
    }
  }

  .edit-time {
    flex: 4em;
    min-width: 2em;
    font-size: 2rem;
  }

  .edit-riff-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    button {
      width: 4em;
      height: 4em;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.google-login {
  background-color: $color-primary;
  color: $color-white;
  border: 1px solid $color-primary;
  cursor: pointer;

  &:hover {
    background-color: $color-white;
    color: $color-primary;
  }
}

.get-started-instructions {
  justify-content: center;
  display: flex;
  align-items: center;
}

.riff-detail-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.riff-list-title {
  text-align: center;
  font-size: 2.5rem;
  width: 100%;
  // border-top: 4px solid $color-primary;
  padding-top: 3rem;
}

.riff-detail-selected {
  background-color: lightskyblue;
}

@media (min-width: 1020px) {
  .App {
    margin: 0;
    padding: 0;
    height: 100%;
    min-height: 100%;
  }
  .main-section {
    height: 100%;
    min-height: 100vh;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }

  .control-panel {
    height: 100%;
    min-height: 125vh;
    width: 30%;
    margin-top: 0;
    scrollbar-color: white;
    font-size: $small;
  }
}

.view-share-riff-link {
  margin-top: 1.5rem;
}

.add-riff-title {
  text-align: center;
  font-size: 2.5rem;
}
