.navbar {
  font-size: $small;
  display: flex;
  justify-content: flex-start;
  width: 50rem;
  position: absolute;
  top: 0;
  left: 0;

  @media (min-width: 700px) {
    width: 70%;
  }

  .navbar-link-active {
    border-bottom: 3px solid $color-primary;
    transition: border-bottom 0.5s ease-in-out;
  }
  a {
    margin-left: 2rem;

    @media (min-width: 700px) {
      margin-left: 5rem;
    }

    &:hover {
      color: $color-primary !important;
    }
  }

  @media (min-width: 700px) {
    font-size: $medium;
  }
}
