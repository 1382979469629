@font-face {
  font-family: 'Limelight';
  src: local('Limelight'),
    url(../../fonts/Limelight-Regular.ttf) format('truetype');
}

body,
html {
  max-width: 100%;
  overflow-x: hidden;
  background-color: $color-white;
  font-family: 'Open Sans', sans-serif;
  color: $color-black;
  margin: 0;
  padding: 0;
}
html {
  font-size: 62.5%;
  font-family: 'Open Sans', sans-serif;
}
p,
h3 {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
  &:hover {
    cursor: pointer;
  }
}

button {
  &:hover {
    cursor: pointer;
  }
}

h2,
h1,
h3,
h4,
h5 {
  font-weight: normal;
}

li {
  list-style-type: none;
}
