a:hover {
  color: $color-primary;
}

.heading-primary-main {
  font-size: $extra-large;
  color: $color-gray;
  font-weight: 700;
  margin: $small;
  margin-top: 3rem;
  font-family: Limelight;

  // @media (min-width: 700px) {
  //   font-size: $extra-large;
  // }
}

.heading-name {
  font-size: $large;
  color: $color-white;
  font-weight: 700;
  margin: $small;
  margin-top: 3rem;
  font-family: Limelight;
}

.heading-qa {
  font-size: $large;
  color: $color-black;
  font-weight: 700;
  margin: $small;
  margin-top: 3rem;
  font-family: Limelight;
}

.account-heading {
  margin-top: 10rem;
}
