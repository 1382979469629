$color-primary: #a41320;
$color-white: #fff;
$color-black: #000;
$color-gray: #55596d;
$color-dark-gray: #121212;
$color-light-gray: #eceff1;
$color-reddish-black: #1e1213;

// SIZES
$small: 1.6rem;
$medium: 3.2rem;
$large: 4.8rem;
$extra-large: 6.4rem;
