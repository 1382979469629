.container-riff-meta {
  display: flex;
  height: $small;
  background-color: $color-dark-gray;
  position: relative;
  width: 97.75%;

  margin: 0 auto;
  @media (min-width: 501px) {
    width: 100%;
  }

  .riff-meta {
    background-color: $color-light-gray;
    height: $small;
    position: absolute;
  }

  .riff-own-meta {
    background-color: $color-primary;
    height: $small;
    position: absolute;
  }
}

#meta-play-head {
  height: $small;
  background-color: yellow;
  width: 1px;
  position: absolute;
  z-index: 3;
}
